import * as React from 'react'
import styled from 'styled-components'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'

import Layout from '../components/Layout'
import phone from '../assets/icons/phone.svg'
import contacts from '../assets/images/contact_pics.png'
import letter from '../assets/images/contact_letter.svg'
import bulles from '../assets/images/contact_bulles.png'
import plane from '../assets/images/contact_plane.png'
import contact from '../assets/images/contact.png'
import { useState } from 'react'
import axios from 'axios'
import { device } from '../style/variables'
import chevronTop from '../assets/icons/chevron-top.svg'
import chevronBottom from '../assets/icons/chevron-bottom.svg'
import MyButton from '../components/common/Button'
import scrollTo from 'gatsby-plugin-smoothscroll'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1140px;
  margin: auto;
  @media ${device.smartphone} {
    width: 100vw;
    margin: auto;
    margin-top: 30px;
    overflow: hidden;
  }
`

const Title = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 36px;
  color: #001e3f;
  text-align: center;
  margin: 0px;
`

const SubTitle = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 36px;
  color: #001e3f;
  text-align: center;
  margin: 0px;
  margin-bottom: 30px;
`

const FormCard = styled.div`
  width: 806px;

  background: #ffffff;
  box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 80px;
  padding: 30px;
  position: relative;
  @media ${device.smartphone} {
    width: 100%;
    margin: auto;
    margin: 10px;
    margin-top: 30px;
    overflow: hidden;
  }
`

const CrispCard = styled.div`
  width: 806px;

  background: #ffffff;
  box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 90px;
  padding: 30px;
  position: relative;
  @media ${device.smartphone} {
    width: 100%;
    margin: auto;
    margin: 10px;
    margin-top: 30px;
    overflow: hidden;
  }
`

const PhoneCard = styled.div`
  width: 806px;

  background: #ffffff;
  box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 90px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  @media ${device.smartphone} {
    width: 100%;
    margin: auto;
    margin: 10px;
    margin-top: 30px;
    overflow: hidden;
  }
`

const CardTitle = styled.p`
  margin: 0px;
  top: 1004px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  color: #001e3f;
`

const CardLabel = styled.p`
  margin: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  color: #7c8c9f;
`

const Blue = styled.span`
  color: #0e47c1;
  font-weight: bold;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`

const LetterImage = styled.img`
  position: absolute;
  top: -25px;
  right: -25px;
`

const BullesImage = styled.img`
  position: absolute;
  top: -25px;
  left: -65px;
`

const PlaneImage = styled.img`
  position: absolute;
  top: -40px;
  left: -65px;
  z-index: 10;
`

const Input = styled.input<{ width?: string }>`
  height: 64px;
  background: #ffffff;
  border: 1px solid #dfe4ec;
  border-radius: 10px;
  padding: 0.625rem;
  font-size: 0.9075rem;
  width: ${props => props.width || 'auto'};
  @media ${device.smartphone} {
    width: 100%;
  }
`

const Label = styled.p`
  margin: 0px;
  color: #001e3f;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 4px;
`

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.smartphone} {
    margin-bottom: 10px;
  }
`

const Row = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  & > * {
    width: ${props => (props.fullWidth ? '100%' : 'auto')};
    margin-right: ${props => (props.fullWidth ? '0px' : '60px')};
  }
  @media ${device.smartphone} {
    flex-wrap: wrap;
    margin: 0px;
    & > * {
      width: 100% !important;
      margin-right: 0px !important;
    }
  }
`

const Textarea = styled.textarea`
  background: #ffffff;
  border: 1px solid #dfe4ec;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  resize: none;
`

const SendButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`

const StyledDropdown = styled(Dropdown)`
  & .Dropdown-control {
    border-radius: 10px;
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    cursor: pointer;
    border: 1px solid #dfe4ec;
  }

  & .Dropdown-arrow-wrapper {
    position: absolute;
    right: 16px;
  }
`

const Contacts = styled.img`
  @media ${device.smartphone} {
    display: none;
  }
`
const ContactImage = styled.img`
  position: absolute;
  right: -125px;
  top: -55px;
  z-index: -1;
`

const EmailSent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 60px;
  text-align: center;
`

const options = [
  'Je souhaite contacter le service location',
  'Je souhaite contacter un agent pour un bien en vente',
  "Je souhaite contacter le service d'Administration des Ventes",
  'Je souhaite contacter un agent pour un projet de vente',
  'Je souhaite rejoindre le réseau allowa network',
  'Je souhaite devenir partenaire allowa et proposer mes services',
  'Je souhaite contacter le service Institutionnel de Allowa',
  'Autres',
]

const genders = ['M', 'Mme']

const emailRegexp = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
const phoneRegexp = /^[0-9]{10}$/

const Contact = () => {
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    gender: '',
    object: options[3],
    message: '',
  })
  const [sent, setSent] = useState(false)

  const onChange = (property: string, value: string) => {
    setData(prev => ({ ...prev, [property]: value }))
  }

  const checkField = field => field && field.length > 0

  const checkEmail = () => data.email.match(emailRegexp) !== null

  const checkPhone = () => data.phone.match(phoneRegexp) !== null

  const sendEmail = () => {
    axios.post('https://api.allowa.com/v1/contact/send', {
      name: `${data.gender} ${data.lastName} ${data.firstName}`,
      to: 'stephane.genty@allowa.com',
      ...data,
    })
    setSent(true)
    scrollTo('#title')
  }

  const openCrisp = () => {
    if (window && window.$crisp) {
      window.$crisp.push(['do', 'chat:open'])
    }
  }

  return (
    <Layout>
      <Container>
        <Title id="title">
          Vous souhaitez <Blue>nous contacter</Blue> ?
        </Title>
        <SubTitle>
          <Blue>Trois canaux</Blue> pour avoir une réponse assurée !
        </SubTitle>

        {/* FORM CARD */}
        <FormCard>
          <PlaneImage src={plane} className="desktop-only" />
          <ContactImage src={contact} className="desktop-only" />
          <CardTitle>Par e-mail</CardTitle>
          {sent ? (
            <EmailSent>
              <Label>👍</Label>
              <Label>Votre message a bien été envoyé.</Label>
              <Label>Vous serez recontacté dans les plus brefs délais par nos équipes.</Label>
            </EmailSent>
          ) : (
            <>
              <CardLabel>
                Laissez-nous un message, un <b>expert Allowa</b> vous répondra dans les plus brefs
                délais.
              </CardLabel>
              <Row>
                <InputRow>
                  <Label>Civilité*</Label>
                  <StyledDropdown
                    options={genders}
                    onChange={value => onChange('gender', value.value)}
                    value={data.gender}
                    placeholder="Civilité"
                    arrowOpen={<img src={chevronTop} style={{ marginLeft: 10, marginTop: 6 }} />}
                    arrowClosed={
                      <img src={chevronBottom} style={{ marginLeft: 10, marginTop: 6 }} />
                    }
                  />
                </InputRow>
                <InputRow>
                  <Label>Nom*</Label>
                  <Input
                    type="text"
                    value={data.lastName}
                    width="170px"
                    onChange={e => onChange('lastName', e.target.value)}
                    placeholder="Votre nom"
                  />
                </InputRow>
                <InputRow>
                  <Label>Prénom*</Label>
                  <Input
                    type="text"
                    value={data.firstName}
                    width="170px"
                    onChange={e => onChange('firstName', e.target.value)}
                    placeholder="Votre prénom"
                  />
                </InputRow>
              </Row>
              <Row>
                <InputRow>
                  <Label>E-mail*</Label>
                  <Input
                    type="text"
                    value={data.email}
                    width="250px"
                    onChange={e => onChange('email', e.target.value)}
                    placeholder="Votre adresse e-mail"
                  />
                </InputRow>
                <InputRow>
                  <Label>Numéro de téléphone*</Label>
                  <Input
                    type="text"
                    value={data.phone}
                    width="250px"
                    onChange={e => onChange('phone', e.target.value)}
                    placeholder="Votre téléphone"
                  />
                </InputRow>
              </Row>
              {data.object && data.object.length > 0 && (
                <InputRow>
                  <Label>Votre message*</Label>
                  <Textarea
                    rows={7}
                    placeholder="Quelle est votre question ?"
                    value={data.message}
                    onChange={e => onChange('message', e.target.value)}
                  />
                </InputRow>
              )}
              <SendButton>
                <MyButton
                  allowaBlue
                  onClick={sendEmail}
                  label="Envoyer"
                  isDisabled={
                    !Object.getOwnPropertyNames(data).every(prop => checkField(data[prop])) ||
                    !checkPhone() ||
                    !checkEmail()
                  }
                />
              </SendButton>
            </>
          )}
        </FormCard>

        {/* CRIPS CHATTING CARD */}
        <CrispCard>
          <BullesImage src={bulles} className="desktop-only" />
          <CardTitle>Discutez avec nous</CardTitle>
          <CardLabel>
            Echangez directement avec l'un de <b>nos experts</b> via notre chat en ligne.
          </CardLabel>
          <FlexBetween>
            <Contacts src={contacts} className="desktop-only" />
            <MyButton allowaBlue onClick={() => openCrisp()} label="Discuter avec un expert" />
          </FlexBetween>
        </CrispCard>

        {/* PHONE/POSTAL CARD */}
        <PhoneCard>
          <LetterImage src={letter} className="desktop-only" />
          <CardTitle>De manière plus traditionnelle</CardTitle>
          <CardLabel style={{ marginBottom: 10 }}>
            <b>Par courrier à l’adresse suivante</b> :{' '}
            <Blue>Allowa SAS, 52B rue de la Bienfaisance 75008 Paris</Blue>
          </CardLabel>
          <Flex>
            <CardLabel style={{ marginRight: 10 }}>Par téléphone : </CardLabel>
            <MyButton
              allowaBlue
              onClick={() => (window.location = 'tel:+33500000000')}
              startIcon={<img src={phone} className="desktop-only" />}
              label="09 70 82 35 54"
            />
          </Flex>
        </PhoneCard>
      </Container>
    </Layout>
  )
}

export default Contact
